export default [
    {
        to: {name: 'admin.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'admin.price-plans.index'},
        icon: 'mdi-application-cog',
        text: 'Price Plans',
    },
    {
        to: {name: 'admin.users.index'},
        icon: 'mdi-account',
        text: 'Users',
    },
    {
        to: {name: 'admin.intervals'},
        icon: 'mdi-av-timer',
        text: 'Intervals',
    },
    {
        to: {name: 'admin.workouts'},
        icon: 'mdi-weight-lifter',
        text: 'Workouts',
    },
    {
        to: {name: 'admin.xrays'},
        icon: 'mdi-radiology-box',
        text: 'Xrays',
    },
]
